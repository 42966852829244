<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <div class="px-4 py-2 flex justify-content-between align-items-center">
            <h2 class="font-weight-normal">Contact persons</h2>
            <mercur-button :to="addSupplierContactPersonRoute" class="btn btn-raised btn-yellow text-uppercase">
                <i class="fas fa-plus"></i>
                Add new contact
            </mercur-button>
        </div>
        <ag-grid-vue
            class="ag-grid fill ag-theme-material border"
            :columnDefs="columnDefs"
            :animateRows="true"
            rowModelType="serverSide"
            :pagination="true"
            :enableRangeSelection="false"
            :suppressCellSelection="true"
            @grid-ready="onGridReady"
        ></ag-grid-vue>
    </mercur-card>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'
import CONFIG from '@root/config'

export default {
    name: 'ContactPersonsOverview',
    components: { AgGridVue },
    data () {
        return {
            gridApi: null,
            columnDefs: null,
        }
    },
    computed: {
        addSupplierContactPersonRoute () {
            return {
                name: 'SupplierAddContactPerson',
                params: {
                    ...this.$route.params,
                },
            }
        },
    },
    beforeMount () {
        this.columnDefs = [
            {
                field: 'lastName',
            },
            {
                field: 'firstName',
            },
            {
                field: 'roles',
            },
            {
                headerName: 'Created',
                field: 'dateCreated',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: 'Updated',
                field: 'dateUpdated',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
            {
                headerName: '',
                width: 100,
                cellRendererFramework: 'ActionsCell',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                text: 'Edit',
                                icon: 'fas fa-edit',
                                to: (params) => {
                                    return {
                                        name: 'SupplierEditContactPerson',
                                        params: {
                                            ...params.data,
                                        },
                                    }
                                },
                            },
                        ],
                    }
                },
            },
        ]
    },
    methods: {
        onGridReady (params) {
            this.gridApi = params.api
            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: CONFIG.API.ROUTES.SUPPLIERS.CONTACT_PERSONS.OVERVIEW.replace('{supplierId}', this.supplierId),
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    const contactPersons = data.items

                    if (contactPersons.length === 0) {
                        params.api.showNoRowsOverlay()
                    }
                    successCallback(contactPersons, contactPersons[0].totalRows)
                    this.$store.dispatch('contactPersons/setSupplierContactPersons', {
                        supplierId: this.supplierId,
                        contactPersons,
                    })
                },
            })
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit()
            }, 100)
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            params.api.setServerSideDatasource(this.serverSideDatasource)
        },
        onModelUpdated () {
            if (this.gridApi) {
                this.gridApi.sizeColumnsToFit()
            }
        },
    },
}
</script>
